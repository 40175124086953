import { template as template_5d1bf5f4d15644f0ba5070da50b41faa } from "@ember/template-compiler";
const WelcomeHeader = template_5d1bf5f4d15644f0ba5070da50b41faa(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
