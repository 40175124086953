import { template as template_30163105bf284975a7e9b64905776603 } from "@ember/template-compiler";
const FKControlMenuContainer = template_30163105bf284975a7e9b64905776603(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
