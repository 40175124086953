import { template as template_ad6febfd7cdf433f864ff32f7e7213dc } from "@ember/template-compiler";
const SidebarSectionMessage = template_ad6febfd7cdf433f864ff32f7e7213dc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
