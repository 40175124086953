import { template as template_476a41aa68ab4dd3ae13f3ae2e663646 } from "@ember/template-compiler";
const FKText = template_476a41aa68ab4dd3ae13f3ae2e663646(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
